export type AntaaAccountTypeString = 'doctor' | 'student' | 'corporation';

const DOCTOR = 'doctor';
const STUDENT = 'student';
const CORPORATION = 'corporation';

export const AntaaAccountType: {
  DOCTOR: AntaaAccountTypeString;
  STUDENT: AntaaAccountTypeString;
  CORPORATION: AntaaAccountTypeString;
} = {
  DOCTOR,
  STUDENT,
  CORPORATION,
};
