import { SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { AnalyticsEventProps } from '../common/libs/googleAnalytics';
import ArticleSummary, { Props as ArticleSummaryProps } from './ArticleSummary';

export type { ArticleHeader } from './ArticleSummary';

function useLayoutType(isMobile = false): ArticleSummaryProps['layoutType'] {
  const defaultBreakpoint = isMobile ? undefined : 'sm';
  return useBreakpointValue(
    {
      base: 'horizontal',
      sm: 'vertical',
    },
    defaultBreakpoint,
  );
}

export default function ArticlesGrid({
  articles,
  isMobile = true,
  gaEventLabel,
  additionalGaEvent,
  isSpecialContent = false,
}: {
  articles: ArticleSummaryProps[];
  isMobile?: boolean;
  gaEventLabel?: string | ((article: ArticleSummaryProps) => string); // articleSummaryのpropsから記事ごとのgaEventLabelを生成する関数を渡せる
  additionalGaEvent?: AnalyticsEventProps;
  isSpecialContent?: boolean;
}) {
  const layoutType = useLayoutType(isMobile);
  const columns = isSpecialContent ? [1, 2, 2] : [1, 2, 3];

  return (
    <SimpleGrid columns={columns} spacing={4} w="full">
      {articles.map((article) => (
        <ArticleSummary
          key={`${article.articleId}-${article.status}`}
          {...article}
          layoutType={layoutType}
          gaEventLabel={
            gaEventLabel &&
            (typeof gaEventLabel === 'string'
              ? gaEventLabel
              : gaEventLabel(article))
          }
          additionalGaEvent={
            additionalGaEvent && {
              ...additionalGaEvent,
              label: `${article.articleId}${additionalGaEvent.label || ''}`,
            }
          }
        />
      ))}
    </SimpleGrid>
  );
}
